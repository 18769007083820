import Fishing from './index.jsx'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    activeRoute: state.fish.activeRoute,
    activeDisplay: state.fish.activeDisplay,
    activeStep: state.fish.activeStep,
    activeSlide: state.fish.activeSlide,
    animationIsActive: state.fish.animationIsActive,
    email: state.fish.email,
    location: state.router.location
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Fishing)
