const initialState = {
  activeRoute: '',
  activeDisplay: '',
  activeStep: 1,
  activeSlide: 1,
  isFinishSecond: false,
  animationIsActive: false,
  email: ''
}

export default (state = initialState) => {
  return state
}
