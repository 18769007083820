import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: 0.3s;
  box-sizing: border-box;
  min-height: inherit;
  animation-fill-mode: forwards;
  overflow: hidden;
  padding: 0 0 1rem 0;
  @media(min-width: 768px){
    padding: 0;
  }`

Wrapper.Bg = styled.div`
  //min-height: 100vh;
  min-height: var(--app-height);
  box-sizing: border-box;
  background: ${props => {
  let color
  switch(props.activeDisplay) {
    case 'slider':
      // color = 'rgba(229, 229, 229, 0.3)'
      color = 'white'
      break
  }
  return color
}};


`

Wrapper.ButtonBlock = styled.div`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

Wrapper.Button = styled.div`
  height: 3.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.isForecastRoute ? '8px' : '3px'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: ${props => props.isForecastRoute ? '22px' : '1.5rem'} ;
  line-height: 2rem;
  color: #ffffff;
  background: #00A1C6;
  cursor: pointer;

`

Wrapper.Privacy = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  //height: 40px;
  a {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #9A9A9A;
  }

  span {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #A0A0A0;
  }
`

Wrapper.Privacy = styled.div`
  margin-top: 16px;
  //margin-bottom: 16px;
  height: 40px;
  box-sizing: border-box;
  a {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #9A9A9A;
  }
  span {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #A0A0A0;
  }
`

export { Wrapper }