import amplitude from 'amplitude-js'
import Cookies from 'js-cookie'
import {VERSION} from '../constants/data'

export const setVersion = () => {
  return amplitude.getInstance().setVersionName(VERSION)
}

export const userPropertyPreInsertProp = (prop, value) => {
  let identify = new amplitude.Identify().preInsert(prop, value)
  return amplitude.identify(identify)
}

export const firstOpenAction = () => {
  return amplitude.getInstance().logEvent('First_Open_action')
}

export const userStepsBegin = (step) => {

  const { aviluna: { ip = {} } = {} } = window
  if (ip) {
    const { cloudflare = null, aviluna = null } = ip
    const identify = new amplitude.Identify()
    .set('ip-info', {
      'cloudflare': cloudflare,
      'aviluna': aviluna,
      'same': aviluna === cloudflare
    })
    amplitude.getInstance().identify(identify)
  }

  return amplitude.getInstance().logEvent('User_Steps_begin', { 'result': step })
}

export const onboardingLoading = () => {
  const identify = new amplitude.Identify()
    .set('Onboarding', 'forecast')
    .set('fbp', Cookies.get('_fbp') || 'none')
    .set('fbc', Cookies.get('_fbc') || 'none')
    .set('ttclid', Cookies.get('ttclid') || 'none')
    .set('gclid', Cookies.get('gclid') || 'none')
    .set('href', (() => {
      const currentUrl = new URL(window.location)
      if (currentUrl.href && currentUrl.href.length > 1) {
        return currentUrl.href
      }
      return null
    })() || 'none')
  return amplitude.getInstance().identify(identify)
}

