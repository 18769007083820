export const ttFirstScreenAction = () => {
  if (window.ttq != null) {
    window.ttq.track('ViewContent')
  }
}

export const ttOpenAppStoreAction = () => {
  if (window.ttq != null) {
    window.ttq.track('AddPaymentInfo')
  }
}

