import React, {Fragment, useEffect} from 'preact/compat'
import {Wrapper} from './styled'
import {firstOpenAction, onboardingLoading, userStepsBegin} from '../../amplitude/events'
import {clickUrl} from '../../tracking/links'
import amplitude from 'amplitude-js'
import {API_KEY} from '../../amplitude/options'
import FishingForecast from './FishingForecast/FishingForecast'
import {I18n} from 'react-redux-i18n'
import Cookies from 'js-cookie'
import {fbOpenAppStoreAction} from '../../fbpixel/events'
import {ttOpenAppStoreAction} from '../../ttpixel/events'

const sliderRender = () => {

  const handleGoToStore = () => {
    userStepsBegin()
    fbOpenAppStoreAction()
    ttOpenAppStoreAction()
    setTimeout(() => {
      redirectFunction()
    }, 1000)
  }

  const redirectFunction = () => {
    window.location.href = clickUrl()
  }

  return (
    <Fragment>
      <FishingForecast loading={false} forecastView={0}/>
      <Wrapper.ButtonBlock>
        <Wrapper.Button onClick={() => {
            handleGoToStore()
        }}>
        { I18n.t('continue') }
        </Wrapper.Button>
      </Wrapper.ButtonBlock>
    </Fragment>
  )
}

export default function Fishing(props) {
  const {
    activeDisplay,
    animationIsActive,
  } = props

  // for Ios height calculating
  useEffect(() => {
    let userId = Cookies.get('avilunaid')
    amplitude.getInstance().init(
      API_KEY,
      userId,
      {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
        logAttributionCapturedEvent: true
      }
    )
    onboardingLoading()
    firstOpenAction()
  }, [])

  return (
    <Wrapper.Bg activeDisplay={activeDisplay}>
      <Wrapper activeDisplay={activeDisplay} className={animationIsActive ? 'animated' : '' } style={{height: 'auto'}}>
        {
          sliderRender()
        }
      </Wrapper>
    </Wrapper.Bg>
  )
}
