import React, {useEffect} from 'preact/compat'
import './App.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import {
  FORECAST_ROUTE,
} from './constants/routes'
import Fishing from './components/Fishing/FishingContainer'

function App() {
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    setAppHeight()
    window.addEventListener('resize', setAppHeight)
    return () => window.removeEventListener('resize', setAppHeight)
  }, [])

  return (
    <div className="App">
      <Router>
        <Route render={() => <Fishing route={FORECAST_ROUTE}/>} />
      </Router>
    </div>
  )
}

export default App
