import Cookies from 'js-cookie'
import md5 from 'crypto-js/md5'
import amplitude from 'amplitude-js'
import { APP_BRANCH_URL, APP_MYTRACKER_URL } from '../constants/finish'

export const clickUrl = () => {

  const currentUrl = new URL(window.location)
  const query = Object.fromEntries(currentUrl.searchParams.entries())

  const { aviluna: { ip = {}, eventId = null } = {} } = window

  const metadata = Object.assign({}, {
    fbp: Cookies.get('_fbp') || 'none',
    fbc: Cookies.get('_fbc') || 'none',
    ttclid: Cookies.get('ttclid') || 'none',
    gclid: Cookies.get('gclid') || 'none',
    aviluna_id: Cookies.get('avilunaid') || 'none',
    hashed_aviluna_id: (() => {
      const avilunaId = Cookies.get('avilunaid') || 'none'
      return avilunaId !== null ? md5(avilunaId).toString() : null
    })(),
    event_id: eventId,
    amplitude_id: amplitude.getInstance()._metadataStorage.load().deviceId || null,
    agent: window.navigator.userAgent || null,
    referrer: window.document.referrer || null,
    ip: ip.cloudflare || ip.aviluna || null,
    timestamp: Date.now() / 1000,
    href: (() => {
      if (currentUrl.href && currentUrl.href.length > 1) {
        return currentUrl.href
      }
      return null
    })() || 'none'
  })

  const params = Object.assign({}, query, {
    cid: query.fbclid || query.ttclid || query.gclid || '',
    fbp: Cookies.get('_fbp') || 'none',
    fbc: Cookies.get('_fbc') || 'none',
    agent: window.navigator.userAgent || null,
    metadata: JSON.stringify(metadata),
    landing: 'forecast'
  })

  const isMyTracker = (query.mt === 'true' || query.mt === '1')
  if (isMyTracker) {
    const url = new URL(APP_MYTRACKER_URL)
    const myTrackerParams = {
      mt_network: params.utm_source || '',
      mt_campaign: params.utm_campaign || '',
      mt_adset: params.utm_content || '',
      mt_creative: params.utm_creative || '',
      mt_sub1: params.utm_placement || '',
      mt_sub2: params.utm_term || '',
      mt_sub3: params.utm_medium || '',
      cid: params.cid || '',
      metadata: JSON.stringify(metadata),
      landing: params.landing || 'unknown'
    }
    Object.entries(myTrackerParams).forEach(entry => {
      const [key, value] = entry
      url.searchParams.append(key, value)
    })
    console.log(`my tracker url: ${url}`)
    return url
  } else {
    const url = new URL(APP_BRANCH_URL)
    Object.entries(params).forEach(entry => {
      const [key, value] = entry
      url.searchParams.append(key, value)
    })
    console.log(`branch url: ${url}`)
    return url
  }

}
